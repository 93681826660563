import React, { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { ReactComponent as Sopp } from '../assets/thune_sopp.svg'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-hot-toast'
import { useScrollRestoration } from '../scroll'
import { atom, useRecoilState } from 'recoil'
import { getUnit } from './item/page'

export const SalePage = () => {
    const url = useRadarURL()
    useScrollRestoration()

    const { data, isLoading } = useQuery(['sales'], () =>
        fetch(`${url}/v1/radar/sale`).then(async res => {
            if (res.status !== 200) throw new Error(await res.text())
            return res.json()
        }))

    return (
        <div>
            <div className="p-4">
                <div className="relative max-w-6xl mx-auto">
                    <nav className="flex justify-between items-center py-2 px-2 rounded-xl mb-8 relative">
                        <BritneyPopup/>
                        <Sopp className="h-10 px-4"/>
                    </nav>
                </div>
                <div className="relative max-w-4xl mx-auto">
                    {isLoading ? (
                        <Loading/>
                    ) : (
                        <Sale sale={data}/>
                    )}
                </div>
            </div>
        </div>
    )
}

const Loading = () => (
    <div className="flex justify-center items-center">
        <i className="fad fa-spinner-third text-5xl text-purple-400 animate-spin"/>
    </div>
)

export const BritneyPopup = () => {
    const flatter = () => {
        const randomIndex = getRandomInt(britneyReferances.length - 1)
        toast(`${britneyReferances[randomIndex]}. You're on my radar ❤️`, { duration: 6000 })
    }

    return (
        <div className="flex flex-col justify-center items-center">
            <button
                onClick={flatter}
                className="text-4xl text-purple-400 font-black skew-x-12 hover:bg-green-300 hover:text-purple-600 my-0 py-0 px-4 rounded-xl">
                RADAR
            </button>
        </div>
    )
}

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max))
}

const britneyReferances = [
    'Confidence is a must',
    'Cockiness is a plus',
    'Edginess is a rush',
    `Edges I like 'em rough`,
    'A man with a Midas touch',
    'Intoxicate me',
    `I'm a lush`,
    `Stop you're making me blush`,
    'People are looking at us'
]

const weedays = [
    'Søndag',
    'Mandag',
    'Tirsdag',
    'Onsdag',
    'Torsdag',
    'Fredag',
    'Lørdag'
]

function Sale({ sale }) {
    const { sender, reciever, access, metadata } = sale

    return (
        <div>
            <WelcomeMessage sender={sender} reciever={reciever}/>
            <ItemLister sale={sale}/>
            <Metadata metadata={metadata}/>
            <Access access={access}/>
            <BraggingRights/>
        </div>
    )
}

const Metadata = ({ metadata }) => {
    return (
        <div className="bg-gradient-to-br from-purple-700 to-blue-600 px-3 py-1 rounded-xl my-4">
            <p className="font-bold text-indigo-200"><i className="fad fa-glasses-alt"/> For Spesielt Interesserte</p>
            <div>
                <p className="text-indigo-300">Ordre Nummer: {metadata.order_no}</p>
            </div>
        </div>
    )
}

const WelcomeMessage = ({ sender, reciever }) => {
    const weekday = dayjs().day()

    return (
        <div className="text-indigo-100 text-center mb-4">
            {reciever?.length > 1 && (
                <h2 className="font-bold">Hei, {reciever}!</h2>
            )}
            <h3>
                Alle fra {sender} ønsker deg en fin {weedays[weekday]} 🎉
            </h3>
        </div>
    )
}

const ItemLister = ({ sale }) => {
    const { item_no } = useParams()
    const items = sale?.items?.filter(item => item.no !== item_no)
    if (!sale.items || sale.items.length === 0) return <Empty/>

    return (
        <div>
            {items?.map((item, i) => (
                <div
                    className="bg-gradient-to-br p-4 from-indigo-500 to-purple-700 mb-4 rounded-xl overflow-hidden"
                    key={i}>
                    <Item item={item}/>
                </div>
            ))}
        </div>
    )
}

const Empty = () => (
    <div className="bg-gradient-to-br from-red-400 to-pink-500 m-auto p-4 rounded-xl">
        <p className="text-center text-gray-800 font-medium">
            <i className="fad fa-calendar-exclamation text-2xl z-10"/>
            <br/>
            Denne orderen er ikke planlagt enda. Sjekk igjen senere!
        </p>
    </div>
)

const Item = ({ item }) => {
    const url = useRadarURL()
    if (!item) return null
    const { no } = item

    return (
        <div className="flex flex-col p-2">
            <div className="flex overflow-x-scroll space-x-2 rounded-xl ">
                <img className="max-h-32 rounded-xl border-4 border-indigo-300 "
                     src={`${url}/v1/radar/item-picture?item=${no}`}
                     onError={(e) => e.target.style.display = 'none'} alt=""
                />
                {item?.as_built?.map(asBuilt => (
                    <AsBuilt small key={asBuilt.ID} asBuilt={asBuilt}/>
                ))}
            </div>
            <ItemDescription item={item}/>
            <ProgressBar {...item.statistics}/>
            <ReportsLister item={item}/>

            <div className="flex flex-col space-y-2 mt-2">
                {item.sub_items?.map((subItem, index) => (
                    <SubItem key={index} subItem={subItem}/>
                ))}
            </div>

        </div>
    )
}

const useAtomWithID = (id, defaultValue) => {
    return useMemo(() => {
        return atom({
            key: id,
            default: defaultValue
        })
    }, [id])
}

const itemPath = (order, line, routing) => {
    return `${encodeURIComponent(order)}/${encodeURIComponent(line)}/${encodeURIComponent(routing)}`
}

const SubItem = ({ subItem }) => {
    const [showSubItems, setShowSubItems] = useRecoilState(useAtomWithID(`show_sub_items:${itemPath(subItem?.prod_order_no, subItem?.prod_order_line_no, subItem?.routing_no)}`, false))
    const toggle = () => setShowSubItems(!showSubItems)
    const url = useRadarURL()
    const params = useParams()

    return (
        <div>
            <div className="flex">
                {subItem.sub_items && (
                    <div className="inline-block group cursor-pointer" onClick={toggle}>
                        <div className="w-1 ml-1 mr-2 rounded-full bg-indigo-200 group-hover:bg-pink-400 h-full block"/>
                    </div>
                )}

                <div className="w-full">
                    <div className="flex justify-between items-center w-full">
                        <div className="flex space-x-2 items-center">
                            <img className="w-10 h-10 object-cover rounded-lg "
                                 src={`${url}/v1/radar/item-picture?item=${subItem.no}`} alt=""/>
                            <Link
                                to={`/sale/token/${params.token}/${encodeURIComponent(subItem.prod_order_no)}/${encodeURIComponent(subItem.line_no)}/${encodeURIComponent(subItem.routing_no)}`}
                                className="font-medium text-indigo-200 hover:underline">{subItem.description}
                            </Link>
                        </div>
                        <div className="w-16">
                            <ProgressBar {...subItem.statistics} />
                        </div>
                    </div>

                    <ReportsLister item={subItem}/>

                    {showSubItems && (
                        <div className="ml-4 mt-2 space-y-2">
                            {subItem.sub_items?.map((subSubItem, index) => (
                                <SubItem key={index} subItem={subSubItem}/>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export const AsBuilt = ({ asBuilt, small = false }) => {
    const url = useRadarURL()

    return (
        <img
            className={`rounded-xl ${small ? 'h-32 w-32' : 'h-60 w-60'}  object-cover inline-block w-full h-full object-cover`}
            src={`${url}/v1/radar/as-built/thumbnail?id=${asBuilt.ID}`} alt="" key={asBuilt.ID}
        />
    )
}

export const ItemDescription = ({ item }) => {
    const clientURL = useRadarClientURL()
    const url = useRadarURL()
    const { no, description, due_date } = item

    return (
        <div className="flex items-center py-2">
            <a href={`mailto:${item.owner_email}`}>
                <img
                    className="w-14 h-14 object-cover rounded-full border-2 border-indigo-300 hover:border-green-300"
                    onError={(e) => e.target.style.display = 'none'}
                    src={`${url}/v1/radar/profile-picture?email=${item.owner_email}`}
                    alt=""
                />
            </a>
            <div className="ml-2 text-indigo-100 flex flex-col sm:flex-row justify-between sm:items-center w-full">
                <div>
                    <Link
                        to={`${clientURL}/${encodeURIComponent(item.prod_order_no)}/${encodeURIComponent(item.line_no)}/${encodeURIComponent(item.routing_no)}`}
                        className="font-bold">{description}</Link>
                    <p>{no}</p>
                    <p className="font-medium text-indigo-200">{dayjs(due_date).format('DD MMMM YYYY')}</p>
                </div>
                <ShowQuantity item={item} />
            </div>
        </div>
    )
}


const ShowQuantity = ({ item }) => {
    if (item?.unit_of_measure?.length <= 0) return null

    return (
        <div>
            <p>{item.amount}{getUnit(item.unit_of_measure ?? "")}</p>
        </div>
    )
}

export const ProgressBar = ({ absolute_percentage, live_percentage }) => {

    return (
        <div className="relative flex ">
            <div
                style={{ width: `${absolute_percentage}%` }}
                className="rounded z-30 bg-green-400">
                <p className="text-center font-bold text-indigo-600 px-1">
                    {absolute_percentage}%
                </p>
            </div>
            <div
                style={{ width: `${absolute_percentage + live_percentage}%` }}
                className="absolute left-0 top-0 bottom-0 rounded bg-green-400 bg-opacity-50 animate-pulse z-20"
            />
            <div
                className="absolute inset-0 inline-flex h-full w-full rounded opacity-50 z-10 bg-gradient-to-br from-indigo-300 to-purple-300"
            />
        </div>
    )
}

export const ReportsLister = ({ item }) => {
    return (
        <div className="flex flex-wrap">
            {item?.reports?.map(report => (
                <div key={report.id} className="w-full sm:w-auto mt-2 sm:mr-2">
                    <Report report={report}/>
                </div>
            ))}
        </div>
    )
}

const Report = ({ report }) => {
    const url = useRadarURL()
    const d = dayjs(report.last_modified).locale('nb').format('DD MMMM YYYY')

    return (
        <a href={`${url}/v1/radar/report?id=${encodeURIComponent(report.id)}`} key={report.id} target={'_blank'}>
            <div
                className="bg-indigo-400 sm:hover:bg-indigo-300 transition-colors px-3 py-2 rounded-xl flex items-center group">
                <div className="mr-3">
                    <i className="fad fa-cloud-download-alt text-xl text-indigo-800 group-hover:text-green-700"/>
                </div>
                <div>
                    <p className="font-bold group-hover:underline text-sm"> {report.filename}</p>
                    <div className="flex">
                        <p className="">{(report.size / 1000000).toFixed(1)}MB</p>
                        <p className="px-2">·</p>
                        <p>{d}</p>
                    </div>
                </div>
            </div>
        </a>
    )
}

const Access = ({ access }) => {
    const grammar = access?.length > 1 ? 'personer' : 'person'

    return (
        <div className="mt-16">
            <div className="text-indigo-100">
                <p className="font-bold"><i className="fad fa-users text-xl"/> {access?.length} {grammar} kan se denne
                    siden
                </p>
                <p className="font-medium text-gray-300">
                    Denne siden inneholder et unikt passord for deg.
                    Hvis du vil dele den med noen, anbefaler vi å invitere dem,
                    i stedet for å spre din URL.
                </p>
            </div>
            {access?.map(a => <Person key={a.id} access={a}/>)}
            <GiveAccess/>
        </div>
    )
}

const Person = ({ access }) => {
    const { name, email, inviter } = access
    const mutation = useUninviteMutation(access.id)

    const handleUninvite = () => {
        const sure = window.confirm(`Sikker på at du vil fjerne tilgangen til ${name}?`)
        if (sure) {
            mutation.mutate()
        }
    }

    return (
        <div className="bg-gray-800 rounded-xl p-4 text-gray-300 my-4 flex justify-between items-center">
            <div>
                <p className="text-gray-100 font-bold p-0 m-0">{name}</p>
                <p className="p-0 m-0">{email}</p>
                <p>Invitert av {inviter}</p>
            </div>
            <div>
                <button onClick={handleUninvite} className="text-gray-200 hover:bg-gray-700 rounded-xl p-4 text-xl">
                    {mutation.isLoading ? (
                        <i className="fad fa-spinner-third animate-spin"/>
                    ) : (
                        <i className="fad fa-minus-circle"/>
                    )}
                </button>
            </div>
        </div>
    )
}

const GiveAccess = () => {
    const [show, setShow] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')

    const open = () => setShow(true)
    const close = () => setShow(false)

    const mutation = useInviteMutation(name, email, () => {
        setShow(false)
        setName('')
        setEmail('')
    })

    return (
        <div className="relative">
            <button
                onClick={open}
                className="block m-auto bg-purple-400 text-gray-800 hover:bg-purple-300 px-4 py-2 rounded-xl font-bold">
                <i className="fas fa-plus-circle"/> Invite Person
            </button>

            {show && (
                <div
                    className="flex flex-col absolute left-0 right-0 bottom-0 rounded-xl pop-open bg-gradient-to-br from-purple-300 to-purple-600 p-4 max-w-xs m-auto z-30">
                    <div className="text-center text-gray-800">
                        <p className="text-xl font-bold">Hvem vil du invitere?</p>
                        <p className="font-medium">
                            Hen vil bare se denne siden. Vi sender en E-Post med sporingslink, akkurat slik du fikk.
                        </p>
                    </div>
                    <div className="flex flex-col pt-4">
                        <label className="font-medium" htmlFor="name">Navn</label>
                        <GiveAccessInput
                            id="name"
                            placeholder="Kari Nordmann"
                            autoFocus
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col pt-4">
                        <label className="font-medium" htmlFor="email">E-Post</label>
                        <GiveAccessInput
                            id="email"
                            placeholder="kari@eksempel.no"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col mt-6">
                        <button disabled={mutation.isLoading}
                                className="bg-green-300 disabled:opacity-50 disabled:cursor-wait text-gray-800 font-bold px-4 py-2 rounded-xl"
                                onClick={mutation.mutate}>
                            {mutation.isLoading ? (
                                <i className="fad fa-spinner-third animate-spin text-lg"/>
                            ) : 'Inviter'}
                        </button>
                        <button className="bg-red-300 text-gray-800 font-bold px-4 py-2 rounded-xl mt-2"
                                onClick={close}>
                            Avbryt
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

const GiveAccessInput = ({ className, ...props }) =>
    <input type="text"
           className={`px-4 py-2 text-xl rounded-xl bg-purple-700 text-purple-100 placeholder-purple-200 ${className}`} {...props}/>

const BraggingRights = () => {
    return (
        <div className="mt-32 text-purple-400 text-center">
            <p className="font-bold px-8 py-4 bg-gray-900">
                Thune Connect ©
            </p>
        </div>
    )
}

const useInviteMutation = (name, email, callback) => {
    const url = useRadarURL()
    const client = useQueryClient()

    const handler = () => fetch(`${url}/v1/radar/invite`, {
        method: 'POST',
        body: JSON.stringify({
            'name': name,
            'email': email
        })
    })

    return useMutation(handler, {
        onSuccess: async () => {
            await client.invalidateQueries(['sales'])
            callback()
        },
        onError: () => {
            window.alert('Invitasjon Misslykkes')
        }
    })
}

const useUninviteMutation = (id) => {
    const url = useRadarURL()
    const client = useQueryClient()

    const handler = () => fetch(`${url}/v1/radar/uninvite`, {
        method: 'POST',
        headers: {
            'id': id
        }
    }).then(res => {
        if (res.status !== 200) throw new Error('whaat')
    })

    return useMutation(handler, {
        onSuccess: async () => {
            await client.invalidateQueries(['sales'])
        },
        onError: () => {
            window.alert('Fail')
        }
    })
}

export const useRadarClientURL = () => {
    const { secret_id, secret_code, token } = useParams()

    if (token) {
        return `/sale/token/${token}`
    }

    return `/sale/${secret_id}/${secret_code}`
}


export const useRadarURL = () => {
    const { secret_id, secret_code, token } = useParams()

    if (token) {
        return `/api/kanban/token/${token}`
    }

    return `/api/kanban/${secret_id}/${secret_code}`
}

