import { useEffect, useLayoutEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { atom, useRecoilState } from 'recoil'

export const useScrollRestoration = () => {
    const location = useLocation()
    const [scroll, setScroll] = useRecoilState(useScrollPositionWithID(location.pathname))

    useLayoutEffect(() => {
        window.scroll(0, scroll)
    }, [location.pathname])

    const handleScroll = () => {
        setScroll(window.scrollY)
        console.log(window.scrollY)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

}

const useScrollPositionWithID = (id) => {
    return useMemo(() => {
        return atom({
            key: id,
            default: 0
        })
    }, [id])
}
