import { ReactComponent as Sopp } from '../../assets/thune_sopp.svg'
import { AsBuilt, BritneyPopup, ItemDescription, ProgressBar, ReportsLister, useRadarURL } from '../page'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

const useRoute = (prodOrderNo, lineNo, routingNo) => {
    const url = useRadarURL()

    const { data, ...rest } = useQuery(['sales', 'item', prodOrderNo, lineNo, routingNo],
        () => fetch(`${url}/v1/radar/sale/item?prod_order_no=${encodeURIComponent(prodOrderNo)}&routing_no=${encodeURIComponent(routingNo)}&line_no=${encodeURIComponent(lineNo)}`)
            .then(async res => {
                if (res.status !== 200) throw new Error(await res.text())
                return res.json()
            }), {suspense: true})

    return {
        route: data,
        ...rest
    }
}

const useItemParams = () => {
    const { token, prod_order_no, line_no, routing_no } = useParams()

    return {
        token,
        prod_order_no: decodeURIComponent(prod_order_no),
        line_no: decodeURIComponent(line_no),
        routing_no: decodeURIComponent(routing_no),
    }
}

const SalesItemPage = () => {
    const url = useRadarURL()

    const { token, prod_order_no, line_no, routing_no } = useItemParams()
    const { route, isLoading } = useRoute(prod_order_no, line_no, routing_no)

    if (isLoading) return <p>loading</p>

    return (
        <div className="p-2 sm:p-4">
            <div className="relative max-w-6xl mx-auto">
                <nav className="flex justify-between items-center py-2 px-2 rounded-xl mb-8 relative">
                    <BritneyPopup/>
                    <Sopp className="h-10 px-4"/>
                </nav>
            </div>
            <div className="max-w-4xl m-auto">
                <Link to={`/sale/token/${token}`}>
                    <div
                        className="rounded-t-xl p-4 mx-8 mb-[1px] m-auto bg-indigo-300/40 hover:bg-indigo-300/50 hover:underline decoration-gray-300">
                        <p className="text-center font-bold text-indigo-200">Tilbake til prosjektet.</p>
                    </div>
                </Link>
                <div
                    className="bg-gradient-to-br shadow-xl shadow-indigo-800/20 from-indigo-500 to-purple-700 rounded-2xl py-4 sm:py-8">
                    <div className="px-4 sm:px-8">

                        <div className="flex overflow-x-scroll space-x-2 rounded-xl ">
                            <img className="max-h-60 object-cover rounded-xl border-4 border-indigo-300 "
                                 src={`${url}/v1/radar/item-picture?item=${route?.no}`}
                                 onError={(e) => e.target.style.display = 'none'} alt=""
                            />
                            {route?.as_built?.map(asBuilt => (
                                <AsBuilt key={asBuilt.ID} asBuilt={asBuilt}/>
                            ))}
                        </div>

                        <ItemDescription item={route}/>
                        <ProgressBar absolute_percentage={route?.statistics?.absolute_percentage}
                                     live_percentage={route?.statistics?.live_percentage}/>
                        <ReportsLister item={route} />
                    </div>

                    <div className="flex flex-col mt-4 sm:px-4">
                        {route?.components?.map((c, i) => (
                            <Component c={c} key={i}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SalesItemPage

const Component = ({ c }) => {
    const url = useRadarURL()

    return (
        <div>
            <div className="flex space-x-2 hover:bg-indigo-100/10 px-4 py-2 rounded-lg">
                <img className="object-cover rounded-lg w-16 h-16 border-4 border-indigo-200"
                     src={`${url}/v1/radar/item-picture?item=${c.no}`} alt=""/>
                <div className="flex flex-col">
                    <p className="text-indigo-100 font-medium">{c.description}</p>
                    <div className="flex space-x-2">
                        <p className="bg-indigo-100/50 text-indigo-900 rounded-full px-3">{c.no}</p>
                        <p className="text-indigo-100">{c.quantity_used}{getUnit(c.unit_of_measure ?? "")}</p>
                    </div>
                    <div className="flex space-x-2 my-2">
                        {c?.certificates_used?.map((certificate, index) => (
                            <div key={index}>
                                <a href={`${url}/v1/radar/inspect/certificate?id=${certificate.id}`}
                                   target="_blank"
                                   className="bg-green-400 px-3 py-1 rounded-full">
                                    <i className="fad fa-file-certificate mr-1"></i>
                                    {certificate.charge_no}
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const getUnit = (raw) => {
    switch (raw) {
        case 'M2':
            return 'm²'
        case 'M':
            return 'm'
        case 'STK':
            return 'stk'
        default:
            return raw
    }
}