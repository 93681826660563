import { atom, useRecoilState } from 'recoil'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useLayoutEffect, useMemo } from 'react'

export const textState = atom({
    key: 'text-state',
    default: '',
})

export const useTextStateWithID = id => {
    return useMemo(() => {
        return atom({
            key: `text-state-${id}`,
            default: ''
        })
    }, [])
}

export const useScrollPositionWithID = id => {
    return useMemo(() => {
        return atom({
            key: `scroll-${id}`,
            default: 0,
        })
    }, [])
}

export const TestPage = () => {
    const { id } = useParams()
    const [text, setText] = useRecoilState(useTextStateWithID(id))

    const [scroll, setScroll] = useRecoilState(useScrollPositionWithID(id))

    useLayoutEffect(() => {
        window.scroll(0, scroll)
    }, [id])

    const handleScroll = () => {
        setScroll(window.pageYOffset)
        console.log(window.pageYOffset)
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

        return () => window.removeEventListener("scroll", handleScroll)
    }, [])

    const onChange = (event) => {
        setText(event.target.value)
    }

    return (
        <div className="text-indigo-100 max-w-4xl m-auto p-4 min-h-[1000vh] bg-gradient-to-b from-blue-500 to-pink-500">
            <input type="text"
                   className="bg-gray-800 px-3 py-2 rounded-xl text-lg"
                   value={text}
                   onChange={onChange}
            />

            <div className="fixed left-0 bottom-4 right-0 flex space-x-4 justify-center">
                <Link to="/1/foo">
                    1
                </Link>
                <Link to="/2/foo">
                   2
                </Link>
                <Link to="/3/foo">
                    3
                </Link>
            </div>
        </div>
    )
}