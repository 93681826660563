import { useRecoilValue } from 'recoil'
import { useTextStateWithID } from './page'
import { Link, useParams } from 'react-router-dom'

export const FooPage = () => {
    const { id } = useParams()
    const text = useRecoilValue(useTextStateWithID(id))

    return (
        <div className="text-indigo-100 max-w-4xl m-auto p-4">
            <h1>Foo</h1>
            <p>
                Text: {text}
            </p>

            <div className="flex space-x-4 justify-center">
                <Link to="/1/test">
                    1
                </Link>
                <Link to="/2/test">
                    2
                </Link>
                <Link to="/3/test">
                    3
                </Link>
            </div>
        </div>
    )
}