export const PDFPage = () => {

    return (
        <div className="text-gray-100">
            <h1>pdf page</h1>
            <div className="flex">
                <div>
                </div>
                <div>
                    <object
                        width="500"
                        height="700"
                        type="application/pdf"
                        data="https://pdfjs-express.s3-us-west-2.amazonaws.com/docs/choosing-a-pdf-viewer.pdf">
                    </object>
                </div>
            </div>
        </div>
    )
}