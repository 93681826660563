import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { SalePage } from '../sale/page'
import { PDFPage } from '../pdf/page'
import { NotFoundPage } from './404'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { TestPage } from '../test/page'
import { RecoilRoot } from 'recoil'
import { FooPage } from '../test/foo'
import { Toaster } from 'react-hot-toast'
import React from 'react'

const queryClient = new QueryClient()

const SalesItemPage = React.lazy(() => import("../sale/item/page"))

export const Routing = () => {
    return (
        <div className="min-h-screen bg-gray-900">
            <RecoilRoot>
                <QueryClientProvider client={queryClient}>
                    <Toaster/>
                    <BrowserRouter>
                        <Switch>
                            <Route path="/sale/token/:token/:prod_order_no/:line_no/:routing_no">
                                <React.Suspense fallback={<Loading />}>
                                    <SalesItemPage/>
                                </React.Suspense>
                            </Route>
                            <Route path="/sale/:secret_id/:secret_code/:prod_order_no/:line_no/:routing_no">
                                <React.Suspense fallback={<Loading />}>
                                    <SalesItemPage/>
                                </React.Suspense>
                            </Route>
                            <Route path="/sale/token/:token">
                                <SalePage/>
                            </Route>
                            <Route path="/sale/:secret_id/:secret_code/:item_no">
                                <SalePage/>
                            </Route>
                            <Route path="/sale/:secret_id/:secret_code">
                                <SalePage/>
                            </Route>
                            <Route path="/:id/test">
                                <TestPage/>
                            </Route>
                            <Route path="/:id/foo">
                                <FooPage/>
                            </Route>
                            <Route path="/pdf">
                                <PDFPage/>
                            </Route>
                            <Route path="/">
                                <NotFoundPage/>
                            </Route>
                        </Switch>
                    </BrowserRouter>
                    <ReactQueryDevtools initialIsOpen={false}/>
                </QueryClientProvider>
            </RecoilRoot>
        </div>
    )
}


const Loading = () => {
    return (
        <div className="min-h-screen flex justify-center items-center">
        </div>
    )
}