
export const NotFoundPage = () => {

    return (
        <div className="text-center text-indigo-100 py-8">
            <h1 className="text-5xl">404</h1>
            <h2>Page Not Found</h2>
        </div>
    )

}